import React from 'react';
import { PotsOverview } from '@nm-pot/overview';
import { arrayOf, bool, func, object, string } from 'prop-types';
import Panel from '@nutkit/component-panel';
import { Heading, Text, headingLevels } from '@nutkit/component-text';
import Bold, { boldWeights } from '@nutkit/component-bold';
import { linkSizes } from '@nutkit/component-link';
import { buttonCtaTypes } from '@nutkit/component-button';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { Portfolio, SupportArticles } from '@nm-utils-lib-web/routes';
import { Trans } from '@nm-utils-lib-web/translations';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import { WRAPPER_TYPES } from '@nm-pot/common/constants';
import { IncomingTransfers } from '@nm-payments/transfers/components/IncomingTransfers';

import { productListEmptyProductClickedPayload } from '../../../../tracking/events/productList';

const { DASHBOARD_HOST } = global.NutmegConfig;
const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.lisaTab';
const NAVIGATION_TABS_TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';

const LISATab = ({
  t,
  isPresent,
  pots,
  drafts,
  isDraftPresent,
  updatePots,
  updateDrafts,
  userUuid,
  onError,
  withHeading,
  isEligible,
  shouldShowLisaTransfersInTab
}) => {
  const createLISAPotLink = `${DASHBOARD_HOST}${Portfolio.PRODUCT_LISA_INFORMATION_PATH}`;
  const visibleDrafts = isPresent ? [] : drafts;
  const OPEN_OR_TRANSFER_TRANSLATION_NAMESPACE = shouldShowLisaTransfersInTab
    ? `${TRANSLATION_NAMESPACE}.emptyState.transfer`
    : `${TRANSLATION_NAMESPACE}.emptyState.open`;
  const dataQA = shouldShowLisaTransfersInTab
    ? 'product-list__lisa-empty-state-transfer'
    : 'product-list__lisa-empty-state-open';

  if (!isPresent && !isDraftPresent && isEligible) {
    return (
      <>
        {withHeading && (
          <Heading level={headingLevels.THREE} data-qa="product-list__lisa-title">
            {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.lisa.label`)}
          </Heading>
        )}
        <IncomingTransfers wrapperType={WRAPPER_TYPES.LISA} customerUuid={userUuid} />
        <Panel data-qa="product-list__lisa-empty-state">
          <Heading level={headingLevels.TWO} data-qa={`${dataQA}__title`}>
            {t(`${OPEN_OR_TRANSFER_TRANSLATION_NAMESPACE}.title`)}
          </Heading>
          <Text>
            <Trans
              i18nKey={`${OPEN_OR_TRANSFER_TRANSLATION_NAMESPACE}.description`}
              components={[<Bold weight={boldWeights.BOLD}>Bold text</Bold>]}
            />{' '}
            <LinkWithTracking
              data-qa="product-list__lisa-empty-state__learn-more"
              eventPayload={productListEmptyProductClickedPayload({ wrapperType: WRAPPER_TYPES.LISA })}
              isExternal
              href={SupportArticles.FIND_OUT_MORE_LISA}
            >
              {t(`${OPEN_OR_TRANSFER_TRANSLATION_NAMESPACE}.learnMore`)}
            </LinkWithTracking>
          </Text>
          <ButtonGroup align={buttonGroupAlignments.RIGHT} stackUp stackOnMobile>
            <LinkWithTracking
              button
              buttonCta={buttonCtaTypes.PRIMARY}
              size={linkSizes.SM}
              aria-label={t(`${OPEN_OR_TRANSFER_TRANSLATION_NAMESPACE}.link.ariaLabel`)}
              data-qa="product-list__lisa-empty-state__create-new"
              href={createLISAPotLink}
              eventPayload={productListEmptyProductClickedPayload({
                option: shouldShowLisaTransfersInTab ? 'transfer' : 'open',
                wrapperType: WRAPPER_TYPES.LISA
              })}
            >
              {t(`${OPEN_OR_TRANSFER_TRANSLATION_NAMESPACE}.link.label`)}
            </LinkWithTracking>
          </ButtonGroup>
        </Panel>
      </>
    );
  }

  return (
    <>
      {withHeading && (
        <Heading level={headingLevels.THREE} data-qa="product-list__lisa-title">
          {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.lisa.label`)}
        </Heading>
      )}
      <IncomingTransfers wrapperType={WRAPPER_TYPES.LISA} customerUuid={userUuid} />
      <PotsOverview
        customerUuid={userUuid}
        pots={pots}
        drafts={visibleDrafts}
        onClosePot={updatePots}
        updateDrafts={updateDrafts}
        onError={onError}
      />
    </>
  );
};

LISATab.propTypes = {
  t: func.isRequired,
  isPresent: bool.isRequired,
  pots: arrayOf(object).isRequired,
  updatePots: func.isRequired,
  userUuid: string.isRequired,
  onError: func,
  drafts: arrayOf(object),
  isDraftPresent: bool,
  updateDrafts: func.isRequired,
  withHeading: bool,
  isEligible: bool.isRequired,
  shouldShowLisaTransfersInTab: bool.isRequired
};

LISATab.defaultProps = {
  drafts: [],
  isDraftPresent: false,
  withHeading: false,
  onError: undefined
};

export default LISATab;
