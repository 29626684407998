import React, { useMemo } from 'react';
import { JisaDependantsOverview } from '@nm-pot/overview';
import { arrayOf, func, object, string, bool } from 'prop-types';
import Panel, { PanelGhost } from '@nutkit/component-panel';
import { Heading, Text, headingLevels } from '@nutkit/component-text';
import Bold, { boldWeights } from '@nutkit/component-bold';
import { linkSizes, buttonCtas } from '@nutkit/component-link';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { buttonCtaTypes } from '@nutkit/component-button';
import { Customer } from '@nm-utils-lib-web/routes';
import { FIND_OUT_MORE_JISA } from '@nm-utils-lib-web/routes/supportArticles';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import useGetHeadroom from '@nm-portfolio-lib-web/common/hooks/useGetHeadroom';
import { wrapperTypes } from '@nm-portfolio-lib-web/common/constants';
import { IncomingTransfers } from '@nm-payments/transfers/components/IncomingTransfers';
import Amount, { amountStyle } from '@nutkit/component-amount';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import { WRAPPER_TYPES, POT_STATUSES } from '@nm-pot/common/constants';
import Inline, { inlineSpaces } from '@nutkit/component-inline';
import TooltipWithTrigger, { tooltipPlacements, tooltipTriggers } from '@nutkit/component-tooltip';
import Icon, { iconSizes, IconInformation } from '@nutkit/component-icon';
import { useGetCustomerDependantPersonalInformation } from '@nm-customer/common/hooks';
import Loading from '@nutkit/component-loading';

import {
  createNewPotClickedPayload,
  productListEmptyProductClickedPayload
} from '../../../../tracking/events/productList';

const { DASHBOARD_HOST } = global.NutmegConfig;
const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.jisaTab';
const NAVIGATION_TABS_TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';

const JISATab = ({ pots, drafts, onError, userUuid, eligibilityUnknown, isEligible, withHeading }) => {
  const { t } = useTranslation();
  const { data: headroomData, error: headroomError } = useGetHeadroom({
    wrapperType: wrapperTypes.JISA,
    customerUuid: userUuid,
    onError
  });
  const {
    jisaDependants,
    isLoading: isJisaDependantsLoading,
    mutate: mutateJisaDependants
  } = useGetCustomerDependantPersonalInformation(userUuid, { onError });
  const potsToDisplay = useMemo(
    () => pots.filter(({ status }) => [POT_STATUSES.ACTIVE, POT_STATUSES.CLOSING].includes(status)),
    [pots]
  );
  const hasJisaDependants = jisaDependants && jisaDependants.length > 0;

  const handleRemoveJisaAccount = childCustomerId => {
    const newJisaDependants = jisaDependants.filter(({ customerId }) => customerId !== childCustomerId);

    mutateJisaDependants({ jisaDependants: newJisaDependants });
  };

  if (isJisaDependantsLoading) {
    return <Loading />;
  }

  if (!hasJisaDependants && isEligible) {
    return (
      <>
        {withHeading && (
          <Heading level={headingLevels.THREE} data-qa="product-list__jisa-title">
            {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.jisa.label`)}
          </Heading>
        )}
        <IncomingTransfers wrapperType={WRAPPER_TYPES.JISA} customerUuid={userUuid} />
        <Panel data-qa="product-list__jisa-empty-state">
          <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.emptyState.heading`)}</Heading>
          <Text>
            {headroomError
              ? t(`${TRANSLATION_NAMESPACE}.emptyState.description.error`)
              : !!headroomData && (
                  <Trans
                    i18nKey={`${TRANSLATION_NAMESPACE}.emptyState.description.success`}
                    components={[
                      <Bold weight={boldWeights.BOLD}>
                        {' '}
                        <Amount
                          value={headroomData.currentDefaultAllowances.jisa}
                          style={amountStyle.CURRENCY}
                          bolderWeight
                          minDecimals={0}
                        />
                      </Bold>
                    ]}
                  />
                )}{' '}
            <LinkWithTracking
              href={FIND_OUT_MORE_JISA}
              eventPayload={productListEmptyProductClickedPayload({ wrapperType: WRAPPER_TYPES.JISA })}
              aria-label={t(`${TRANSLATION_NAMESPACE}.emptyState.learnMoreLink.ariaLabel`)}
              size={linkSizes.MD}
              data-qa="product-list__jisa-empty-state__learn-more"
              isExternal
            >
              {t(`${TRANSLATION_NAMESPACE}.emptyState.learnMoreLink.label`)}
            </LinkWithTracking>
          </Text>
          <ButtonGroup align={buttonGroupAlignments.RIGHT} stackOnMobile>
            <LinkWithTracking
              href={`${DASHBOARD_HOST}${Customer.JISA_ABOUT_PATH}`}
              button
              buttonCta={buttonCtas.PRIMARY}
              aria-label={t(`${TRANSLATION_NAMESPACE}.emptyState.openOrTransfer.ariaLabel`)}
              size={linkSizes.SM}
              data-qa="product-list__jisa-empty-state__open-jisa"
              eventPayload={productListEmptyProductClickedPayload({
                option: 'open_or_transfer',
                wrapperType: WRAPPER_TYPES.JISA
              })}
            >
              {t(`${TRANSLATION_NAMESPACE}.emptyState.openOrTransfer.label`)}
            </LinkWithTracking>
          </ButtonGroup>
        </Panel>
      </>
    );
  }

  return (
    <>
      {withHeading && (
        <Heading level={headingLevels.THREE} data-qa="product-list__jisa-title">
          <Inline space={inlineSpaces.SM}>
            {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.jisa.label`)}
            <TooltipWithTrigger
              placement={tooltipPlacements.RIGHT}
              trigger={[tooltipTriggers.CLICK, tooltipTriggers.HOVER]}
              message={t(`${TRANSLATION_NAMESPACE}.notification`)}
            >
              <Icon size={iconSizes.SM} component={IconInformation} />
            </TooltipWithTrigger>
          </Inline>
        </Heading>
      )}
      {!withHeading && (
        <Notification
          dismissable={false}
          level={notificationLevels.INFORMATION}
          data-qa="product-list__jisa-notification"
        >
          {t(`${TRANSLATION_NAMESPACE}.notification`)}
        </Notification>
      )}
      <IncomingTransfers wrapperType={WRAPPER_TYPES.JISA} customerUuid={userUuid} />
      {hasJisaDependants ? (
        <JisaDependantsOverview
          customerId={userUuid}
          pots={potsToDisplay}
          drafts={drafts}
          jisaDependants={jisaDependants}
          onRemoveJisaAccount={handleRemoveJisaAccount}
          onError={onError}
        />
      ) : null}
      {!eligibilityUnknown && isEligible && (
        <PanelGhost
          title={t(`${TRANSLATION_NAMESPACE}.create.title`)}
          data-qa="product-list__jisa-ghost"
          actionButtons={[
            <LinkWithTracking
              key="create"
              button
              buttonCta={buttonCtaTypes.SECONDARY}
              aria-label={t(`${TRANSLATION_NAMESPACE}.create.link.ariaLabel`)}
              href={Customer.JISA_ABOUT_PATH}
              eventPayload={createNewPotClickedPayload({ wrapperType: WRAPPER_TYPES.JISA })}
            >
              {t(`${TRANSLATION_NAMESPACE}.create.link.label`)}
            </LinkWithTracking>
          ]}
        >
          {' '}
        </PanelGhost>
      )}
    </>
  );
};

JISATab.propTypes = {
  pots: arrayOf(object).isRequired,
  drafts: arrayOf(object),
  onError: func,
  userUuid: string.isRequired,
  eligibilityUnknown: bool.isRequired,
  isEligible: bool.isRequired,
  withHeading: bool
};

JISATab.defaultProps = {
  drafts: [],
  withHeading: false,
  onError: undefined
};

export default JISATab;
