import React, { useMemo } from 'react';
import { arrayOf, func, object, bool, string, shape } from 'prop-types';
import { PotsOverview } from '@nm-pot/overview';
import useGetUnallocatedCash from '@nm-payments/common/hooks/useGetUnallocatedCash';
import Panel, { PanelGhost } from '@nutkit/component-panel';
import { Heading, Text, headingLevels, textStyles } from '@nutkit/component-text';
import { linkSizes, buttonCtas } from '@nutkit/component-link';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { Portfolio, Pot } from '@nm-utils-lib-web/routes';
import { FIND_OUT_MORE_ISA } from '@nm-utils-lib-web/routes/supportArticles';
import { buttonCtaTypes } from '@nutkit/component-button';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import { WRAPPER_TYPES } from '@nm-pot/common/constants';
import { IncomingTransfers } from '@nm-payments/transfers/components/IncomingTransfers';

import { getSISAEmptyStateHeadingKey } from '../../helpers';
import UnallocatedCash from '../UnallocatedCash';
import PendingWithdrawal from '../PendingWithdrawal';
import PendingTransfers from '../PendingTransfers';
import {
  createNewPotClickedPayload,
  productListEmptyProductClickedPayload,
  createNewCashPotClickedPayload
} from '../../../../tracking/events/productList';
import { OUTGOING_GIA_WITHDRAWAL, OUTGOING_ISA_WITHDRAWAL } from '../../constants/WrapperTransactions';

import EmptyStateDescription from './EmptyStateDescription';

const { DASHBOARD_HOST } = global.NutmegConfig;
const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.sisaTab';
const NAVIGATION_TABS_TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';

const SISATab = ({
  t,
  pots,
  drafts,
  updatePots,
  updateDrafts,
  onError,
  isPresent,
  isDraftPresent,
  userUuid,
  eligibilityUnknown,
  eligibleGIA,
  eligibleSISA,
  isaStatus,
  withHeading,
  tabLabelKey
}) => {
  const { hasUnallocatedCash, data } = useGetUnallocatedCash({ userUuid });
  const pendingWithdrawalPots = pots.filter(({ pending: { withdrawals } }) => withdrawals > 0);
  const totalPendingWithdrawals = pendingWithdrawalPots
    .map(pot => pot.pending.withdrawals)
    .reduce((prev, next) => prev + next, 0);
  const hasPendingWrapperWithdrawals = useMemo(() => {
    return pots.some(pot => {
      const wrapperTransactions = pot && pot.pending && pot.pending.wrapperTransactions;

      return (
        wrapperTransactions &&
        (wrapperTransactions.includes(OUTGOING_ISA_WITHDRAWAL) || wrapperTransactions.includes(OUTGOING_GIA_WITHDRAWAL))
      );
    });
  }, [pots]);
  const hasPendingWithdrawals = pendingWithdrawalPots.length > 0 || hasPendingWrapperWithdrawals;

  if (!isPresent && !isaStatus.active) {
    return (
      <>
        {withHeading && (
          <Heading level={headingLevels.THREE} data-qa="product-list__sisa-title">
            {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.${tabLabelKey}.label`)}
          </Heading>
        )}
        {hasUnallocatedCash && <UnallocatedCash amount={data.currentValue} potUuid={data.uuid} />}
        {isDraftPresent && (
          <PotsOverview customerUuid={userUuid} drafts={drafts} updateDrafts={updateDrafts} onError={onError} />
        )}
        <IncomingTransfers wrapperType={WRAPPER_TYPES.SISA} customerUuid={userUuid} />
        <Panel data-qa="product-list__empty-state__panel">
          <Heading level={headingLevels.TWO}>
            {t(getSISAEmptyStateHeadingKey(TRANSLATION_NAMESPACE, eligibleGIA, eligibleSISA))}
          </Heading>
          <EmptyStateDescription
            eligibleGIA={eligibleGIA}
            eligibleSISA={eligibleSISA}
            userUuid={userUuid}
            onError={onError}
            learMore={
              <LinkWithTracking
                href={FIND_OUT_MORE_ISA}
                eventPayload={productListEmptyProductClickedPayload({ wrapperType: WRAPPER_TYPES.SISA })}
                aria-label={t(`${TRANSLATION_NAMESPACE}.emptyState.learnMoreLink.ariaLabel`)}
                size={linkSizes.MD}
                data-qa="product-list__sisa-tab__create-new"
                isExternal
              >
                {t(`${TRANSLATION_NAMESPACE}.emptyState.learnMoreLink.label`)}
              </LinkWithTracking>
            }
          />
          <ButtonGroup align={buttonGroupAlignments.RIGHT} stackOnMobile>
            {eligibleGIA && (
              <LinkWithTracking
                href={`${DASHBOARD_HOST}${Portfolio.PRODUCT_GIA_INFORMATION_PATH}`}
                button
                buttonCta={buttonCtas.PRIMARY}
                aria-label={t(`${TRANSLATION_NAMESPACE}.emptyState.openGIALink.ariaLabel`)}
                size={linkSizes.SM}
                data-qa="product-list__sisa-empty-state__open-gia"
                eventPayload={productListEmptyProductClickedPayload({
                  option: 'open_gia',
                  wrapperType: WRAPPER_TYPES.SISA
                })}
              >
                {t(`${TRANSLATION_NAMESPACE}.emptyState.openGIALink.label`)}
              </LinkWithTracking>
            )}
            {eligibleSISA && (
              <LinkWithTracking
                href={Portfolio.PRODUCT_SISA_INFORMATION_PATH}
                button
                buttonCta={buttonCtas.PRIMARY}
                aria-label={t(`${TRANSLATION_NAMESPACE}.emptyState.openISALink.ariaLabel`)}
                size={linkSizes.SM}
                data-qa="product-list__sisa-empty-state__open-isa"
                eventPayload={productListEmptyProductClickedPayload({
                  option: 'open_or_transfer',
                  wrapperType: WRAPPER_TYPES.SISA
                })}
              >
                {t(`${TRANSLATION_NAMESPACE}.emptyState.openISALink.label`)}
              </LinkWithTracking>
            )}
          </ButtonGroup>
        </Panel>
      </>
    );
  }

  return (
    <>
      {withHeading && (
        <Heading level={headingLevels.THREE} data-qa="product-list__sisa-title">
          {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.${tabLabelKey}.label`)}
        </Heading>
      )}
      {hasUnallocatedCash && <UnallocatedCash amount={data.currentValue} potUuid={data.uuid} />}
      {hasPendingWithdrawals && (
        <PendingWithdrawal
          pendingWithdrawalPots={pendingWithdrawalPots}
          totalPendingWithdrawals={totalPendingWithdrawals}
          updatePots={updatePots}
          userUuid={userUuid}
          hasPendingWrapperWithdrawals={hasPendingWrapperWithdrawals}
          data-qa="product-list__sisa-tab__pending-withdrawal"
        />
      )}
      <PendingTransfers userUuid={userUuid} onCancel={updatePots} pots={pots} />
      <IncomingTransfers wrapperType={WRAPPER_TYPES.SISA} customerUuid={userUuid} />
      {(isPresent || isDraftPresent) && (
        <PotsOverview
          customerUuid={userUuid}
          pots={pots}
          drafts={drafts}
          onClosePot={updatePots}
          updateDrafts={updateDrafts}
          onError={onError}
        />
      )}
      {!eligibilityUnknown && (eligibleSISA || eligibleGIA) && (
        <PanelGhost
          title={t(`${TRANSLATION_NAMESPACE}.create.title`)}
          data-qa="product-list__sisa-tab__create-pot"
          actionButtons={[
            <LinkWithTracking
              key="createCashLink"
              button
              buttonCta={buttonCtaTypes.SECONDARY}
              aria-label={t(`${TRANSLATION_NAMESPACE}.create.links.cash.ariaLabel`)}
              href={Pot.getPotNewPath({
                wrapperType: WRAPPER_TYPES.SISA,
                isCashPot: true,
                productType: WRAPPER_TYPES.GIA
              })}
              eventPayload={createNewCashPotClickedPayload({ wrapperType: WRAPPER_TYPES.SISA })}
            >
              {t(`${TRANSLATION_NAMESPACE}.create.links.cash.label`)}
            </LinkWithTracking>,
            <LinkWithTracking
              key="createInvesmentLink"
              button
              buttonCta={buttonCtaTypes.SECONDARY}
              aria-label={t(`${TRANSLATION_NAMESPACE}.create.links.investment.ariaLabel`)}
              href={Pot.getPotNewPath({ wrapperType: WRAPPER_TYPES.SISA, productType: WRAPPER_TYPES.GIA })}
              eventPayload={createNewPotClickedPayload({ wrapperType: WRAPPER_TYPES.SISA })}
            >
              {t(`${TRANSLATION_NAMESPACE}.create.links.investment.label`)}
            </LinkWithTracking>
          ]}
        >
          <Text noStack textStyle={textStyles.TEXT_2}>
            {t(`${TRANSLATION_NAMESPACE}.create.description`)}
          </Text>
        </PanelGhost>
      )}
    </>
  );
};

SISATab.propTypes = {
  t: func.isRequired,
  isPresent: bool.isRequired,
  pots: arrayOf(object).isRequired,
  updatePots: func.isRequired,
  updateDrafts: func.isRequired,
  onError: func,
  userUuid: string.isRequired,
  eligibilityUnknown: bool.isRequired,
  eligibleGIA: bool.isRequired,
  eligibleSISA: bool.isRequired,
  isaStatus: shape({
    active: bool.isRequired
  }).isRequired,
  drafts: arrayOf(object),
  isDraftPresent: bool,
  withHeading: bool,
  tabLabelKey: string.isRequired
};

SISATab.defaultProps = {
  drafts: [],
  isDraftPresent: false,
  withHeading: false,
  onError: undefined
};

export default SISATab;
