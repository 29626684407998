import React from 'react';
import { PotsOverview } from '@nm-pot/overview';
import { arrayOf, bool, func, object, string } from 'prop-types';
import Panel from '@nutkit/component-panel';
import { Heading, Text, headingLevels } from '@nutkit/component-text';
import Bold, { boldWeights } from '@nutkit/component-bold';
import { linkSizes } from '@nutkit/component-link';
import { buttonCtaTypes } from '@nutkit/component-button';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { SupportArticles, Pension } from '@nm-utils-lib-web/routes';
import { Trans } from '@nm-utils-lib-web/translations';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import { JOURNEY_TYPES, POT_ID, WRAPPER_TYPES } from '@nm-pot/common/constants';
import { IncomingTransfers } from '@nm-payments/transfers/components/IncomingTransfers';

import { productListEmptyProductClickedPayload } from '../../../../tracking/events/productList';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.pensionTab';
const NAVIGATION_TABS_TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';

const PensionTab = ({ t, isPresent, pots, updatePots, userUuid, onError, withHeading, isEligible }) => {
  if (!isPresent && isEligible) {
    return (
      <>
        {withHeading && (
          <Heading level={headingLevels.THREE} data-qa="product-list__pension-title">
            {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.pension.label`)}
          </Heading>
        )}
        <IncomingTransfers wrapperType={WRAPPER_TYPES.PENSION} customerUuid={userUuid} />
        <Panel data-qa="product-list__pension-empty-state">
          <Heading level={headingLevels.TWO}> {t(`${TRANSLATION_NAMESPACE}.emptyState.title`)}</Heading>
          <Text>
            <Trans
              i18nKey={`${TRANSLATION_NAMESPACE}.emptyState.description`}
              components={[<Bold weight={boldWeights.BOLD}>Bold text</Bold>]}
            />{' '}
            <LinkWithTracking
              data-qa="product-list__pension-empty-state__learn-more"
              isExternal
              href={SupportArticles.FIND_OUT_MORE_PENSION}
              eventPayload={productListEmptyProductClickedPayload({ wrapperType: WRAPPER_TYPES.PENSION })}
            >
              {t(`${TRANSLATION_NAMESPACE}.emptyState.learnMore`)}
            </LinkWithTracking>
          </Text>
          <ButtonGroup align={buttonGroupAlignments.RIGHT} stackUp stackOnMobile>
            <LinkWithTracking
              button
              buttonCta={buttonCtaTypes.PRIMARY}
              size={linkSizes.SM}
              aria-label={t(`${TRANSLATION_NAMESPACE}.emptyState.link.ariaLabel`)}
              data-qa="product-list__pension-empty-state__create-new"
              href={`${global.NutmegConfig.DASHBOARD_HOST}${Pension.getPensionPotJourneyPath({
                potUuid: POT_ID.NEW_POT_UUID,
                journeyType: JOURNEY_TYPES.CREATE_POT
              })}`}
              eventPayload={productListEmptyProductClickedPayload({
                option: 'open_or_transfer',
                wrapperType: WRAPPER_TYPES.PENSION
              })}
            >
              {t(`${TRANSLATION_NAMESPACE}.emptyState.link.label`)}
            </LinkWithTracking>
          </ButtonGroup>
        </Panel>
      </>
    );
  }

  return (
    <>
      {withHeading && (
        <Heading level={headingLevels.THREE} data-qa="product-list__pension-title">
          {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.pension.label`)}
        </Heading>
      )}
      <IncomingTransfers wrapperType={WRAPPER_TYPES.PENSION} customerUuid={userUuid} />
      <PotsOverview customerUuid={userUuid} pots={pots} onClosePot={updatePots} onError={onError} />
    </>
  );
};

PensionTab.propTypes = {
  t: func.isRequired,
  isPresent: bool.isRequired,
  pots: arrayOf(object).isRequired,
  updatePots: func.isRequired,
  userUuid: string.isRequired,
  onError: func,
  withHeading: bool,
  isEligible: bool.isRequired
};

PensionTab.defaultProps = {
  withHeading: false,
  onError: undefined
};

export default PensionTab;
